import React, { useState, useEffect, useRef } from 'react';

const Counter = ({ target, duration = 2000 }) => {

    const [count, setCount] = useState(0);
    const [inView, setInView] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        const container = counterRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );
        if (container) {
            observer.observe(container);
        }
        return () => {
            if (container) {
                observer.unobserve(container);
            }
        };
    }, []);

    useEffect(() => {
        if (!inView) return;
        const start = Date.now();
        const render = () => {
            const now = Date.now();
            const progress = (now - start) / duration;
            const value = Math.round(progress * target);
            setCount(prevCount => {
                if (value >= target) {
                    return target;
                }
                return value;
            });
            if (progress < 1) {
                requestAnimationFrame(render);
            }
        };
        requestAnimationFrame(render);

        return () => {
            setCount(target);
        };
    }, [inView, target, duration]);

    return (
        <span ref={counterRef}>{count}</span>
    );
};

export default Counter;