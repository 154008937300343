// UnderDevelopment.js
import React from "react";

const UnderDevelopment = () => {
  const linkStyle = {
    color: "white",
    fontWeight: "bold", // Makes the text bold
    textDecoration: "underline", // Underlines the text
  };
  return (
    <div className="row d-flex justify-content-center align-items-center">
      <p className="bg-danger text-white p-3 text-center">
        We’re hard at work building something incredible. In the meantime, feel
        free to visit our Associated Company's website:{" "}
        <a
          style={linkStyle}
          href="https://vasundhara.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vasundhara Infotech LLC.
        </a>
      </p>
    </div>
  );
};

export default UnderDevelopment;
