import React from "react";

const SectionHeading = ({ title, image, textColor }) => {
  let leftImage;
  let rightImage;
  if (image === "LightImage") {
    leftImage = "/images/png/left-line-light.webp";
    rightImage = "/images/png/right-line-light.webp";
    textColor = "text-white";
  } else {
    leftImage = "/images/png/left-line.webp";
    rightImage = "/images/png/right-line.webp";
    textColor = "text-black";
  }
  return (
    <div className="d-flex align-items-center justify-content-center gap-xl-5 gap-4">
      <img src={leftImage} alt="left-line" />
      <h2 className={textColor}>{title}</h2>
      <img src={rightImage} alt="right-line" />
    </div>
  );
};

export default SectionHeading;
