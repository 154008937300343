import { Navigate } from "react-router-dom";
import Home from "../pages/home";
import Services from "../pages/services";
import Portfolio from "../pages/portfolio";
import Journey from "../pages/journey";
import Contact from "../pages/contact";
import SitemapGenerator from "../pages/SitemapGenerator";
const baseUrl = 'https://vasundharagames.com';

const Route = [
    {
        path: "/",
        element: (
            <Home />
        ),
    },
    {
        path: "/services",
        element: (
            <Services />
        ),
    },
    {
        path: "/portfolio",
        element: (
            <Portfolio />
        ),
    },
    {
        path: "/journey",
        element: (
            <Journey />
        ),
    },
    {
        path: "/contact",
        element: (
            <Contact />
        ),
    },
    {
        path: "/contact-us",
        element: (
            <Contact />
        ),
    },
    {
        path: "*",
        element: (
            <Navigate to="/" />
        ),
    },
    {
        path: "/sitemap-generator",
        element: <SitemapGenerator baseUrl={baseUrl} />
    }
]

export default Route;