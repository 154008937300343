// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyB8eYz1C2nr0yQjyb8mSi7M2dPCEXK3Whk",
//   authDomain: "web-event-49743.firebaseapp.com",
//   projectId: "web-event-49743",
//   storageBucket: "web-event-49743.appspot.com",
//   messagingSenderId: "373232199300",
//   appId: "1:373232199300:web:3886b2af551c25830344d1",
//   measurementId: "G-KDVF98YL25"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAXKypsAVCLbnri3T5qz8-4wNeQzqhHju8",
  authDomain: "vasundhara-games-website.firebaseapp.com",
  projectId: "vasundhara-games-website",
  storageBucket: "vasundhara-games-website.appspot.com",
  messagingSenderId: "675683596661",
  appId: "1:675683596661:web:28d119c6b3ca5f4f327b3c",
  measurementId: "G-QL2WNMFM7H"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };