import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../layout/header";
import Footer from "../layout/footer";
import SectionHeading from "../components/SectionHeading";
import CustomCursor from "../components/CustomCursor";
import BackToTopButton from "../components/BackToTopButton";
import { Link , useLocation} from "react-router-dom";
import UnderDevelopment from "../components/UnderDevelopment";
//import { logEvent } from '../firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebaseConfig';
const Journey = () => {
  // useEffect(() => {
  //   logEvent('VG_JN', { page_path: '/journey' });
  // }, []);

  const location = useLocation();
  const handleClick = (page) => {
    const fromPage = location.pathname;

    // Map the page paths to custom event strings
    const pageMappings = {
        "/journey": "VG_JN",
    };

    // Map the transition strings
    const transitionMappings = {
        "/journey": {
            "/contact": "VG_JN_CU",
        }
    };

    // Get the event string for the transition
    const pageString = transitionMappings[fromPage]?.[page] || pageMappings[page] || "UNKNOWN";

   // console.log(`From Page: ${fromPage}, Visit String: ${pageString}`);

    if (analytics) {
        logEvent(analytics, pageString, {
            button_name: "LET'S GET CONNECT & SEE MORE",
            page_title: document.title,
        });
    }

    // Navigate to the new page after logging the event
    // navigate(page);
};



  return (
    <>
      <Helmet>
      <link rel="canonical" href="https://vasundharagames.com/journey" />
        <title>Vasundhara Game Studio | Journey</title>
        
      </Helmet>
      <CustomCursor />
      <Header />
      <section className="about-vasundhara">
        <div className="container">
          <div className="vasundhara-content">
            <h1>
              About <span>Vasundhara</span>
            </h1>
            <p>
              Serving customers with the latest tech-infused software solutions,
              our innovation is what sets us apart from others. We are your
              partners in technical transformation.
            </p>
            <Link to="/contact" onClick={() => handleClick("/contact")}>
              <button className="btn btn-outline d-flex align-items-center gap-2">
                Let's Get Connect
                <img src="/images/svg/arrow-right.svg" alt="arrow" />
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="our-portfolio light-bg">
        <div className="mt-2">
          <UnderDevelopment />
        </div>
      </section>
      <section className="company-journey section-py">
        <div className="container">
          <div className="row justify-content-center position-relative">
            <div className="col-xxl-10 section-heading">
              <SectionHeading title="Company Journey" image="LightImage" />
              <p>
                Vasundhara Game Studios is a reliable game development partner,
                having solid experience of many years in providing game
                development services to clients in the USA, UK, UAE, India, and
                other parts of the world. Our standards are on partner with the
                top game and app development companies and technology ventures
                in the industry today.
              </p>
            </div>
            <div className="col-sm-10">
              <div className="journey-details">
                <div className="journey-year">2024</div>
                <div className="journey-content">
                  We are moving at the Speed of Rocket.
                </div>
              </div>
              <div className="journey-details">
                <div className="journey-content">
                  Started riding the NFT bandwagon with 300 team members.
                </div>
                <div className="journey-year">2022</div>
              </div>
              <div className="journey-details">
                <div className="journey-year">2019</div>
                <div className="journey-content">
                  We grew to 150 team members with outsourcing and 3D
                  departments in tow.
                </div>
              </div>
              <div className="journey-details">
                <div className="journey-content">
                  Vasundhara was now 70 people strong. The game development
                  department started operations.
                </div>
                <div className="journey-year">2018</div>
              </div>
              <div className="journey-details">
                <div className="journey-year">2016</div>
                <div className="journey-content">
                  We started working on app development projects.
                </div>
              </div>
              <div className="journey-details">
                <div className="journey-content">
                  We grew our team to 7 people who helped us with freelancing
                  projects.
                </div>
                <div className="journey-year">2015</div>
              </div>
              <div className="journey-details">
                <div className="journey-year">2013</div>
                <div className="journey-content">
                  Vasundhara started its operations with a 2 person team -
                  Chirag and Somish.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTopButton />
    </>
  );
};

export default Journey;
