import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="footer-wrapper section-py">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-7 col-lg-8">
                            <div className="row">
                                <div className="col-sm-12 d-lg-none">
                                    <Link to="/">
                                        <img src="/images/png/logo.webp" className="logo-wrapper img-fluid" alt="logo" />
                                    </Link>
                                </div>
                                <div className="col-lg-8">
                                    <h3 className="d-lg-block d-none">Vasundhara Game Studio</h3>
                                    <ul>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/">About Us</Link>
                                        </li>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/">Portfolio</Link>
                                        </li>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/">Industries</Link>
                                        </li>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/">HTML Sitemap</Link>
                                        </li>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/contact">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-4">
                                    <h3>Our Service</h3>
                                    <ul>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/">2D/3D Design Art</Link>
                                        </li>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/">2D/3D Animation</Link>
                                        </li>
                                        <li>
                                            <img src="/images/svg/footer-arrow.svg" alt="footer-arrow" />
                                            <Link to="/">Game Development</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <div className="contact-info">
                                        <h4>Phone :</h4>
                                        <ul>
                                            <li>Sales: (+91) 7359349940</li>
                                            <li>HR: (+91) 9408596531</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="contact-info">
                                        <h4>Email :</h4>
                                        <ul>
                                            <li><Link to="/">Info@vasundharagames.com</Link></li>
                                            <li><Link to="/">hr.games@vasundhara.io</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="contact-info mt-sm-5">
                                        <h4>Address :</h4>
                                        <p>5th floor, Vasundhara Infotech Building, Opp Nayara Petrol Pump, Singanpore Road, Katargam, Surat, Gujarat 395004</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 sub-footer">
                            <div className="row align-items-end gy-4">
                                <div className="col-lg-6">
                                    <div className="social-icons">
                                        <Link to="https://www.linkedin.com/company/vasundhara-infotech">
                                            <img src="/images/svg/linkedin.svg" alt="social-icon" />
                                        </Link>
                                        <Link to="https://www.youtube.com/channel/UCdYB-meKG7hRLu626_GQPaA">
                                            <img src="/images/svg/youtube.svg" alt="social-icon" />
                                        </Link>
                                        <Link to="https://www.behance.net/vasundharainfotech">
                                            <img src="/images/svg/behance.svg" alt="social-icon" />
                                        </Link>
                                        <Link to="https://www.facebook.com/VasundharaInfotech">
                                            <img src="/images/svg/facebook.svg" alt="social-icon" />
                                        </Link>
                                        <Link to="https://twitter.com/vasundhara_info">
                                            <img src="/images/svg/twitter.svg" alt="social-icon" />
                                        </Link>
                                        <Link to="https://join.skype.com/invite/vY8x79lYrKvD">
                                            <img src="/images/svg/skype.svg" alt="social-icon" />
                                        </Link>
                                        <Link to="https://www.instagram.com/vasundhara_infotech">
                                            <img src="/images/svg/instagram.svg" alt="social-icon" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 text-center">
                                    <span>INDIA | USA</span>
                                    <p>Copyright @2024 Vasundhara Infotech All Right Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer