import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import route from './helper/route';
import './assets/css/fonts.css';
import './assets/css/style.css';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'VasundharaGames', {
        page_path: location.pathname,
        page_title: document.title,
      });
    }
  }, [location]);

  return null;
}
function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <PageViewTracker />
          <Routes>
            {route?.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={route.element}
                />
              );
            })}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
