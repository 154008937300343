import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link , useLocation } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionHeading from "../components/SectionHeading";
import CustomCursor from "../components/CustomCursor";
import BackToTopButton from "../components/BackToTopButton";
import Counter from "../components/Counter";
import Globe from "../components/Globe";
import AOS from "aos";
import "aos/dist/aos.css";
import UnderDevelopment from "../components/UnderDevelopment";
import ContectCompo from "../components/ContectCompo";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebaseConfig';

const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  // useEffect(() => {
  //   logEvent('VG_SV', { page_path: '/services' });
  // }, []);
  const [smallSize, setSmallSize] = useState(false);
  useEffect(() => {
    const setBackgroundImageBasedOnScreenSize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 767) {
        setSmallSize(true);
      } else {
        setSmallSize(false);
      }
    };
    setBackgroundImageBasedOnScreenSize();
    window.addEventListener("resize", setBackgroundImageBasedOnScreenSize);
    return () => {
      window.removeEventListener("resize", setBackgroundImageBasedOnScreenSize);
    };
  }, []);

  const slider1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const slider2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const slider3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    vertical: true,
    centerMode: true,
  };
  const slider4 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const clients = [
    {
      description:
        "“Working with Bansi was great! Despite some backend issues on our side, she remained patient and collaborated effectively to complete the app.”",
      name: "Yepi Susanti",
      designation: "Project Manager at MRC Ventures Pte Ltd, Singapore",
      image: "/images/png/client-review.webp",
      link: "https://www.upwork.com/freelancers/~01ca6fe600083be89c", // Add the link here if available
      logo: "/images/png/upwork.webp",
    },
    {
      description:
        "“Vasundhara’s exceptional service and dedication consistently exceed our expectations. We value our partnership and look forward to future success together.”",
      name: "Pedro Lama",
      designation: "Entrepreneur & Singer, Russia",
      image: "/images/png/client-review1.webp",
      link: "https://www.freelancer.in/u/vasundhara19?review_context_id=26118652&review_type=project&sb=t", // Add the link here if available
      logo: "/images/png/freelancer.webp",
    },
    {
      description:
        "“Vasundhara Infotech LLP has boosted our productivity and expanded our development capabilities. Their consistently high-quality work has truly impressed us.”",
      name: "Oreen & Nitzan",
      designation: "Mobile Brain LTD, Israel",
      image: "/images/png/client-review2.webp",
      link: "https://clutch.co/go-to-review/d48a7df9-34f5-4632-95da-54975ce9a46e/147740", // Add the link here if available
      logo: "/images/png/clutch.webp",
    },
    {
      description:
        "“It is always a pleasure working with Vasudhara Infotech. They are a responsive, skilled, and reliable team!!”",
      name: "Steve Karigo",
      designation: "Author, Speaker, Consultant Optima Branding, United States",
      image: "/images/png/client-review3.webp",
      link: "https://www.freelancer.in/u/vasundhara19?review_context_id=34667149&review_type=project&sb=t", // Add the link here if available
      logo: "/images/png/freelancer.webp",
    },
    // Add more slides as needed
  ];

  const location = useLocation();
  const handleClick = (page) => {
    const fromPage = location.pathname;

    // Map the page paths to custom event strings
    const pageMappings = {
        "/services": "VG_SV",
    };

    // Map the transition strings
    const transitionMappings = {
        "/services": {
          "/contact": "VG_SV_CU",
        }
    };

    // Get the event string for the transition
    const pageString = transitionMappings[fromPage]?.[page] || pageMappings[page] || "UNKNOWN";

   // console.log(`From Page: ${fromPage}, Visit String: ${pageString}`);

    if (analytics) {
        logEvent(analytics, pageString, {
            button_name: "LET'S GET CONNECT & SEE MORE",
            page_title: document.title,
        });
    }

    // Navigate to the new page after logging the event
    // navigate(page);
};

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://vasundharagames.com/services" />
        <title>Vasundhara Game Studio | Services</title>
      </Helmet>
      <CustomCursor />
      <Header />
      <section className="service-slider">
        {/* <Slider {...slider1}> */}
        {/* {[...Array(6)]?.map((item, i) => ( */}
        {/*  key={i + 1} */}
        <div>
          <div
            style={{
              backgroundImage: `url(${
                smallSize
                  ? `images/png/service-slider-sm.webp`
                  : `images/png/service-slider.webp`
              })`,
            }}
            className="slider-img"
          >
            <div className="container">
              <div className="slider-content">
                <h1>2D Game Art Outsourcing Studio</h1>
                <p>
                  We have talented 2D game artists who have many years of
                  experience working with various 2D game art styles. Vasundhara
                  Game Studios is a highly reputed 2D game art company that
                  delivers exceptional 2D game art in a short span of time. We
                  also provide 2D concept art, design, and animation services.
                </p>
                <Link to="/contact" onClick={() => handleClick("/contact")}>
                  <button className="btn btn-outline d-flex align-items-center gap-2">
                    LET'S GET CONNECT
                    <img src="/images/svg/arrow-right.svg" alt="arrow" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* // ))} */}
        {/* </Slider> */}
      </section>
      {/* <section className="service-slider">
        <Slider {...slider1}>
          {[...Array(6)]?.map((item, i) => (
            <div key={i + 1}>
              <div
                style={{
                  backgroundImage: `url(${
                    smallSize
                      ? `images/png/service-slider-sm.webp`
                      : `images/png/service-slider.webp`
                  })`,
                }}
                className="slider-img"
              >
                <div className="container">
                  <div className="slider-content">
                    <h1>2D Game Art Outsourcing Studio</h1>
                    <p>
                      We have talented 2D game artists who have many years of
                      experience working with various 2D game art styles.
                      Vasundhara Game Studios is a highly reputed 2D game art
                      company that delivers exceptional 2D game art in a short
                      span of time. We also provide 2D concept art, design, and
                      animation services.
                    </p>
                    <Link to="/contact">
                      <button className="btn btn-outline d-flex align-items-center gap-2">
                        LET'S GET CONNECT
                        <img src="/images/svg/arrow-right.svg" alt="arrow" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section> */}
      <section className="our-portfolio light-bg">
        <div className="mt-2">
          <UnderDevelopment />
        </div>
      </section>
      <section className="service-section section-py light-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 section-heading">
              <SectionHeading title="Services" />
              <p className="text-black">
                Vasundhara Game studio has a pool of experienced game artists
                who have worked on numerous top gaming projects over the years.
                We follow a holistic 2D game art outsourcing process which
                enables us to deliver quality in each aspect. Our clientele over
                the years has included top gaming publishers, indie companies,
                and individual developers.
              </p>
            </div>
            <div className="col-sm-12">
              <div className="service-list">
                <h3>1</h3>
                <div>
                  <h4>Concept Art</h4>
                  <p>
                    The experienced artists at our 2D game art studio create
                    concept art that acts as a blueprint for the entire game art
                    team to follow. We explore different styles and ideas to
                    finally nail down the coherent style that perfectly matches
                    the game’s theme and target audience preferences.
                  </p>
                </div>
              </div>
              <div className="service-list">
                <h3>2</h3>
                <div>
                  <h4>Character Art</h4>
                  <p>
                    We create both human and non-human characters, such as
                    fantasy creatures and animals, in different 2D game art
                    styles. Our character designers have a deep understanding of
                    anatomy and know how in-depth characterization can add to a
                    game’s overall experience.
                  </p>
                </div>
              </div>
              <div className="service-list">
                <h3>3</h3>
                <div>
                  <h4>Environment Art</h4>
                  <p>
                    Being a leading 2D game art outsourcing studio, the artists
                    we have create detailed 2D environments such as cities,
                    landscapes, mountain ranges, etc., prioritizing
                    interactivity and navigation. We use multiple references to
                    develop the best output.
                  </p>
                </div>
              </div>
              <div className="service-list">
                <h3>4</h3>
                <div>
                  <h4>Props Art</h4>
                  <p>
                    We develop art for diverse props such as weapons, vehicles,
                    furniture, and other objects. Our 2D artists create static,
                    dynamic, interactive, and supportive props that play a
                    crucial role in the game play.
                  </p>
                </div>
              </div>
              <div className="service-list">
                <h3>5</h3>
                <div>
                  <h4>Illustration and Background Design</h4>
                  <p>
                    Our team provides detailed illustrations and backgrounds
                    that match the theme of the game. Being a top 2D art studio,
                    we place all the elements on the screen in a visually
                    pleasing and aesthetic manner, taking care of aspects such
                    as depth and perspective.
                  </p>
                </div>
              </div>
              <div className="service-list">
                <h3>6</h3>
                <div>
                  <h4>UI/UX Design</h4>
                  <p>
                    We have experienced UI/UX designers who develop intuitive
                    layouts for games. Our designers are adept at creating
                    seamless user experiences that are developed after
                    understanding the overall game and the requirements of the
                    target audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-us section-py">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 section-heading">
              <SectionHeading title="Why Choose Us" image="LightImage" />
              <p>
                Vasundhara Game studio has a pool of experienced game artists
                who have worked on numerous top gaming projects over the years.
                We follow a holistic 2D game art outsourcing process which
                enables us to deliver quality in each aspect. Our clientele over
                the years has included top gaming publishers, indie companies,
                and individual developers.
              </p>
            </div>
            <div className="col-sm-12">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-6">
                  <img
                    src="/images/png/why-us.webp"
                    className="img-fluid why-us-img"
                    alt="why-us"
                  />
                </div>
                <div className="col-xl-7 col-lg-6">
                  <div className="why-us-list" data-aos="fade-in">
                    <h5>Realistic Art</h5>
                    <p>
                      Our experienced artists create characters, props, and
                      environments, replicating their accurate details in
                      real-life. The results are game worlds with intricate and
                      subtle details that immerse players to the max.
                    </p>
                  </div>
                  <div className="why-us-list" data-aos="fade-in">
                    <h5>Abstract Art</h5>
                    <p>
                      We create abstract 2D game art visual styles, utilizing
                      different geometrical shapes and color palettes, thus
                      giving them a unique visual signature. Our team
                      understands the core features of the game and makes
                      stylistic choices in accordance.
                    </p>
                  </div>
                  <div className="why-us-list" data-aos="fade-in">
                    <h5>Stylized Art</h5>
                    <p>
                      For different gaming platforms and game genres, we create
                      stylized art, de-emphasizing realism and focusing on
                      creativity. Our team first understands the game
                      requirements, takes classic references, and produces the
                      best quality output.
                    </p>
                  </div>
                  <div className="d-lg-none text-center my-4">
                    <img
                      src="/images/png/res-why-us.webp"
                      className="img-fluid w-50"
                      alt="why-us"
                    />
                  </div>
                  <div className="why-us-list" data-aos="fade-in">
                    <h5>Pixel Art</h5>
                    <p>
                      We bring the retro pixel game art style to modern gaming.
                      Our team implements this distinct aesthetic seamlessly
                      into games, evoking a feeling of nostalgia and a sense of
                      immersion in the players.
                    </p>
                  </div>
                  <div className="why-us-list" data-aos="fade-in">
                    <h5>Handdrawn Art</h5>
                    <p>
                      Our team meticulously draws different artworks, such as
                      important characters and environments, by hand, thus
                      developing a memorable visual style. The hand-drawn art we
                      create at our 2D game art studio, is detailed, with
                      strategic use of colors and shapes.
                    </p>
                  </div>
                  <div className="why-us-list" data-aos="fade-in">
                    <h5>Cartoonish Art</h5>
                    <p>
                      We create cartoonish art that is inspired by classic
                      animation, exaggerating the features of individual
                      artworks in unrealistic manners, but exhibiting a certain
                      charm that is appealing to adults and children alike.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-portfolio section-py light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 section-heading">
              <SectionHeading title="Our Portfolio" />
            </div>
            <div className="col-sm-10">
              <Tabs
                defaultActiveKey="2d-design"
                className="justify-content-center align-items-center"
              >
                <Tab eventKey="2d-design" title="2D Design Art">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="action-game">
                        <Tab eventKey="action-game" title="Action Game Design">
                          <h3>Action Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="board-game" title="Board Game Design">
                          <h3>Board Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="card-game" title="Card Game Design">
                          <h3>Card Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="hyper-game"
                          title="Hyper Casual Game Design"
                        >
                          <h3>Hyper Casual Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="casual-game" title="Casual Game Design">
                          <h3>Casual Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="3d-design" title="3D Design Art">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="stylized-character">
                        <Tab
                          eventKey="stylized-character"
                          title="Stylized 3D Character"
                        >
                          <h3>Stylized 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="highpoly-character"
                          title="Highpoly 3D Character"
                        >
                          <h3>Highpoly 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="lowpoly-character"
                          title="Lowpoly 3D Character"
                        >
                          <h3>Lowpoly 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="cartoon-character"
                          title="Cartoon 3D Character"
                        >
                          <h3>Cartoon 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="3d-environment" title="3D Environment">
                          <h3>3D Environment</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="2d-animation" title="2D Animation">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="animation-2d">
                        <Tab eventKey="animation-2d" title="2D Animation">
                          <h3>2D Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="motion-graphics" title="Motion Graphics">
                          <h3>Motion Graphics</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="spine-animation" title="Spine Animation">
                          <h3>Spine Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="2d-character-animation"
                          title="2D Character Animation"
                        >
                          <h3>2D Character Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="3d-animation" title="3D Animation">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="animal-animation">
                        <Tab
                          eventKey="animal-animation"
                          title="Animal Animation"
                        >
                          <h3>Animal Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="character-animation"
                          title="Character Animation"
                        >
                          <h3>Character Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="game-animation" title="Game Animation">
                          <h3>Game Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="fps-animation" title="FPS Animation">
                          <h3>FPS Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="rig" title="Rig">
                          <h3>Rig</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='client-review section-py'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 section-heading'>
                            <SectionHeading title="What Our Client Think" image="LightImage" />
                        </div>
                        <div className="col-sm-12">
                            <Slider {...slider2} >
                                {[...Array(5)]?.map((item, i) =>
                                    <div key={i + 1}>
                                        <div className='row align-items-center'>
                                            <div className="col-lg-7">
                                                <p>“Honest, hard working, great graphical taste, morals. Everything you wish to find in a freelancer. One of the best I have ever worked with.”</p>
                                                <h6>Pedro L.</h6>
                                                <span>CEO and Co-founder of Abc Company</span>
                                            </div>
                                            <div className="col-lg-5 client-img">
                                                <img src="/images/png/client-review1.webp" className='img-fluid' alt="client" />
                                                <div className="img-effect"></div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* <section className="client-review section-py">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 section-heading">
              <SectionHeading
                title="What Our Client Think"
                image="LightImage"
              />
            </div>
            <div className="col-sm-12">
              <Slider {...slider2}>
                {/* {[...Array(5)]?.map((item, i) => ( 
                {clients.map((slide, i) => (
                  <div key={i + 1}>
                    <div className="row align-items-center">
                      <div className="col-lg-7">
                        <p>{slide.description}</p>
                        <h6>{slide.name}</h6>
                        <span>{slide.designation}</span>
                      </div>
                      <div className="col-lg-5 client-img">
                        <img
                          src={slide.image}
                          className="img-fluid"
                          alt="client"
                        />
                        <div className="img-effect"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section> */}
      <section className="client-review section-py">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 section-heading">
              <SectionHeading
                title="What Our Client Think"
                image="LightImage"
              />
            </div>
            <div className="col-sm-12">
              <Slider {...slider2}>
                {clients.map((slide, i) => (
                  <div key={i + 1}>
                    <div className="row align-items-center">
                      <div className="col-lg-7">
                        <p>
                          {slide.description}
                          {/* Add extra clickable logo */}
                          <a
                            href={slide.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="extra-logo"
                          >
                            <img
                              src={slide.logo}
                              alt="Extra Logo"
                              style={{ marginTop: "10px" }}
                              width="100" // Set width in pixels
                              height="auto" // Maintain aspect ratio (can be omitted if you set only width)
                              className="img-fluid"
                            />
                          </a>
                        </p>
                        <h6>{slide.name}</h6>
                        <span>{slide.designation}</span>
                      </div>
                      <div className="col-lg-5 client-img">
                        <img
                          src={slide.image}
                          className="img-fluid"
                          alt="client"
                        />
                        <div className="img-effect"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <section className="about-us section-py light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <h2>
                Discover why millions of client choose <span>vasundhara</span>{" "}
                for their business
              </h2>
            </div>
            <div className="col-sm-12">
              <div className="row align-items-sm-center">
                <div className="col-lg-3 col-6">
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about1.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={10} />+ Years
                      </h5>
                      <span>In Industry</span>
                    </div>
                  </div>
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about2.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={2} />x
                      </h5>
                      <span>Growth Rate</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-lg-0 order-first">
                  <div className="globe-container">
                    <Globe />
                    <div className="client-slider d-sm-none">
                      <div className="client-detail">
                        <img src="/images/png/globe-img2.webp" alt="client" />
                        <img src="/images/png/globe-img3.webp" alt="client" />
                        <img src="/images/png/globe-img4.webp" alt="client" />
                        <img src="/images/png/globe-img5.webp" alt="client" />
                        {/* <img src="/images/png/globe-img5.webp" alt="client" /> */}
                      </div>
                      <div className="client-detail">
                        <img src="/images/png/globe-img2.webp" alt="client" />
                        <img src="/images/png/globe-img3.webp" alt="client" />
                        <img src="/images/png/globe-img4.webp" alt="client" />
                        <img src="/images/png/globe-img5.webp" alt="client" />
                        {/* <img src="/images/png/globe-img1.webp" alt="client" /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about3.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={800} />+
                      </h5>
                      <span>Projects Delivered</span>
                    </div>
                  </div>
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about4.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={300} />+
                      </h5>
                      <span>Expert Professionals</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-11">
              <p className="text-center mb-0">
                We focus on providing game development services to create
                projects that stand out and stay in memory for long. Our game
                developers have extensive experience enabling us to help global
                gaming companies with their popular titles.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="technologies-section section-py">
        <div className="container">
          <div className="row">
            <div className="col-xxl-11 section-heading">
              <SectionHeading
                title="Technologies For 2D ART"
                image="LightImage"
              />
              <p>
                We focus on providing game development services to create
                projects that stand out and stay in memory for long. Our game
                developers have extensive experience enabling us to help global
                gaming companies with their popular titles.
              </p>
            </div>
            <div className="col-sm-12">
              <div className="technologies-list">
                <div className="technologies-name">
                  <div className="technologies-img">
                    <img
                      src="/images/png/figma.webp"
                      className="img-fluid"
                      alt="figma"
                    />
                  </div>
                  <h5>Figma</h5>
                </div>
                <div className="technologies-name">
                  <h5>Photoshop</h5>
                  <div className="technologies-img">
                    <img
                      src="/images/png/photoshop.webp"
                      className="img-fluid"
                      alt="photoshop"
                    />
                  </div>
                </div>
                <div className="technologies-name">
                  <div className="technologies-img">
                    <img
                      src="/images/png/illustrator.webp"
                      className="img-fluid"
                      alt="illustrator"
                    />
                  </div>
                  <h5>Illustrator</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-list section-py light-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 section-heading">
              <SectionHeading title="Blog" />
            </div>
            <div className="col-sm-12 d-lg-block d-none">
              <div className="row">
                <div className="col-lg-4">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="/images/png/blog1.webp"
                        className="img-fluid"
                        alt="blog"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src="/images/svg/calendar.svg"
                        className="img-fluid"
                        alt="calendar"
                      />
                      <span>June 6, 2024</span>
                    </div>
                    <h5>Best Software Development Trends to Follow in 2024</h5>
                    <p>
                      The growth of software development in 2024 is ongoing and
                      will persist in the months ahead. We would presumably
                      observe many developments...
                    </p>
                    <Link to="https://vasundhara.io/blogs/best-software-development-trends-in-2024">
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="/images/png/blog2.webp"
                        className="img-fluid"
                        alt="blog"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src="/images/svg/calendar.svg"
                        className="img-fluid"
                        alt="calendar"
                      />
                      <span>June 4, 2024</span>
                    </div>
                    <h5>Why Choose Unity 3D For Game Development Project?</h5>
                    <p>
                      Unity 3D game development is a rapidly growing technology
                      in the field of game development. The programming
                      environment is user-friendly...
                    </p>
                    <Link to="https://vasundhara.io/blogs/unity-3d-game-development-project">
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="/images/png/blog1.webp"
                        className="img-fluid"
                        alt="blog"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src="/images/svg/calendar.svg"
                        className="img-fluid"
                        alt="calendar"
                      />
                      <span>May 31, 2024</span>
                    </div>
                    <h5>
                      How AI is Transforming E-commerce Website Development
                    </h5>
                    <p>
                      The concept of artificial intelligence (AI) has recently
                      gained widespread attention and is now ubiquitous,
                      transcending the realm of frightening futuristic...
                    </p>
                    <Link to="https://vasundhara.io/blogs/aI-transforming-e-commerce-website-development">
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 d-lg-none">
              <Slider {...slider4}>
                <div className="blog-card">
                  <div className="blog-img">
                    <img
                      src="/images/png/blog1.webp"
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src="/images/svg/calendar.svg"
                      className="img-fluid"
                      alt="calendar"
                    />
                    <span>June 6, 2024</span>
                  </div>
                  <h5>Best Software Development Trends to Follow in 2024</h5>
                  <p>
                    The growth of software development in 2024 is ongoing and
                    will persist in the months ahead. We would presumably
                    observe many developments...
                  </p>
                  <Link to="https://vasundhara.io/blogs/best-software-development-trends-in-2024">
                    Read More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
                <div className="blog-card">
                  <div className="blog-img">
                    <img
                      src="/images/png/blog2.webp"
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src="/images/svg/calendar.svg"
                      className="img-fluid"
                      alt="calendar"
                    />
                    <span>June 4, 2024</span>
                  </div>
                  <h5>Why Choose Unity 3D For Game Development Project?</h5>
                  <p>
                    Unity 3D game development is a rapidly growing technology in
                    the field of game development. The programming environment
                    is user-friendly...
                  </p>
                  <Link to="https://vasundhara.io/blogs/unity-3d-game-development-project">
                    Read More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
                <div className="blog-card">
                  <div className="blog-img">
                    <img
                      src="/images/png/blog1.webp"
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src="/images/svg/calendar.svg"
                      className="img-fluid"
                      alt="calendar"
                    />
                    <span>May 31, 2024</span>
                  </div>
                  <h5>How AI is Transforming E-commerce Website Development</h5>
                  <p>
                    The concept of artificial intelligence (AI) has recently
                    gained widespread attention and is now ubiquitous,
                    transcending the realm of frightening futuristic...
                  </p>
                  <Link to="https://vasundhara.io/blogs/aI-transforming-e-commerce-website-development">
                    Read More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
              </Slider>
            </div>
            <div className="col-lg-12 text-center">
              <button className="btn btn-primary">
                Read All Blogs
                <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="process-section section-py">
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 section-heading">
              <SectionHeading title="Process We Follow" image="LightImage" />
              <p>
                We focus on providing game development services to create
                projects that stand out and stay in memory for long. Our game
                developers have extensive experience enabling us to help global
                gaming companies with their popular titles.
              </p>
            </div>
            <div className="col-sm-12 d-flex justify-content-center">
              <div className="process-steps">
                <div className="step-one">
                  <h4>Research</h4>
                  <p>
                    We conduct preliminary research into other games and explore
                    diverse media to help us capture and conceptualize the best
                    visual styles for your game. Our team of artists
                    collaborates on this endeavor to deliver the best work.
                  </p>
                </div>
                <div className="step-two">
                  <h4>Ideation</h4>
                  <p>
                    Our 2D artists experiment and draw out multiple ideas that
                    can be implemented. The goal of this stage is to brainstorm
                    and find different styles in which the same piece of artwork
                    can be represented.
                  </p>
                </div>
                <div className="step-three">
                  <h4>Refinement</h4>
                  <p>
                    We improve the ideas we have developed, refining them in
                    multiple aspects, such as aesthetics and functionality. Our
                    artists spend multiple rounds of iteration to produce the
                    artwork step by step.
                  </p>
                </div>
                <div className="step-four">
                  <h4>Color Implementation</h4>
                  <p>
                    Our 2D artists implement the colors into the artworks after
                    careful analysis and deliberation on color theory. We select
                    the colors most apt to bring out the intent of the artwork
                    inside each game sequence.
                  </p>
                </div>
                <div className="step-five">
                  <h4>Creation of Draft</h4>
                  <p>
                    Our team creates a final draft of the artwork. For this,
                    each artist makes sure the artwork passes the internal
                    quality metrics we set prior to the art production pipeline
                    has commenced.
                  </p>
                </div>
                <div className="step-six">
                  <h4>Review and Iteration</h4>
                  <p>
                    Once the final draft is submitted, our art directors conduct
                    a comprehensive review of each artwork. We provide
                    actionable feedback to further improve the quality of the
                    art, and the artists make iterations based on this.
                  </p>
                </div>
                <h3>THE 2D GAME ART DEVELOPMENT</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContectCompo />
      <section className="mini-banner section-py">
        <div className="container">
          <div className="row align-items-center gy-lg-0 gy-4">
            <div className="col-lg-7 pe-xl-5">
              <h2>Explore Digital Innovations</h2>
              <p>
                We offer robust product strategies, empirical market research,
                intuitive UX design, and reduced time to the market launch
                process.
              </p>
              <button className="btn btn-primary">Get Started</button>
            </div>
            <div className="col-lg-5 ps-xl-5">
              <Slider {...slider3}>
                <div>
                  <span>Game Development</span>
                </div>
                <div>
                  <span>Web Development</span>
                </div>
                <div>
                  <span>Mobile App</span>
                </div>
                <div>
                  <span>Design</span>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTopButton />
    </>
  );
};

export default Services;
