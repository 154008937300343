import React, { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import CustomCursor from "../components/CustomCursor";
import BackToTopButton from "../components/BackToTopButton";
import axios from "axios";
import SectionHeading from "../components/SectionHeading";
//import { logEvent } from '../firebaseConfig';
const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    interest: "",
    budget: "",
  });

  const [formStatus, setFormStatus] = useState(""); // To handle success or error messages
  // useEffect(() => {
  //   logEvent('VG_CU', { page_path: '/contact' });
  // }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email && !formData.phoneNumber) {
      setFormStatus("Please provide at least an email or phone number.");
      return; // Stop the form submission
    }

    try {
      await axios.post("https://vasundharagames.com/send-email", formData);
      setFormStatus(
        "Your message has been sent successfully! We will get back to you shortly."
      );
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        interest: "",
        budget: "",
      });
    } catch (error) {
      setFormStatus(
        "Oops! Something went wrong while sending your message. Please try again later."
      );
      console.error(error);
    }
  };
  const statusStyle = formStatus.includes("successfully")
    ? {
        color: "green",
        fontSize: "16px",
        marginTop: "10px",
        fontWeight: "bold",
      }
    : { color: "red", fontSize: "16px", marginTop: "10px", fontWeight: "bold" };

  return (
    <>
      <Helmet>
      <link rel="canonical" href="https://vasundharagames.com/contact" />
        <title>Vasundhara Game Studio | Contact Us</title>
      </Helmet>
      <CustomCursor />
      <Header />
      <section className="contact-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-7 px-0">
              <img
                src="/images/png/vasundhara-building.webp"
                className="img-fluid w-100"
                alt="vasundhara-building"
              />
            </div>
            <div className="col-lg-5 px-0">
              <div className="contact-form">
                <h2>Contact Us</h2>
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                  <Form.Select
                    name="interest"
                    value={formData.interest}
                    onChange={handleChange}
                  >
                    <option value="">I am interested in</option>
                    <option value="Web Development">Game Development</option>
                    <option value="App Development">2D/3D Animation</option>
                    <option value="Game Development">2D/3D Art</option>
                    <option value="Game Development">
                      Website Development
                    </option>
                    <option value="Game Development">App Development</option>
                    <option value="Game Development">Other</option>
                  </Form.Select>
                  <Form.Select
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                  >
                    <option value="">
                    What do you need from us?
                    </option>
                    <option value="Advise">Advise</option>
                    <option value="Develop from Scratch">Develop from Scratch</option>
                    <option value="Support & Maintenance">Support & Maintenance</option>
                    <option value="Optimize">Optimize</option>
                  </Form.Select>
                  <p>
                    This form collects your details to add you to our monthly
                    newsletter list. We treat your data with the utmost security
                    and will never sell it to third parties. Read our{" "}
                    <Link to="/">privacy policy</Link> for more.
                  </p>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Contact Us
                      <FontAwesomeIcon icon={faArrowUp} className="ms-2" />
                    </button>
                  </div>
                  {formStatus && <p style={statusStyle}>{formStatus}</p>}{" "}
                  {/* Display success or error message */}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Vishal Added this */}
      <section className="process-section section-py">
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 section-heading">
              <SectionHeading title="Process We Follow" image="LightImage" />
              <p>
                We focus on providing game development services to create
                projects that stand out and stay in memory for long. Our game
                developers have extensive experience enabling us to help global
                gaming companies with their popular titles.
              </p>
            </div>
            <div className="col-sm-12 d-flex justify-content-center">
              <div className="process-steps">
                <div className="step-one">
                  <h4>Research</h4>
                  <p>
                    We conduct preliminary research into other games and explore
                    diverse media to help us capture and conceptualize the best
                    visual styles for your game. Our team of artists
                    collaborates on this endeavor to deliver the best work.
                  </p>
                </div>
                <div className="step-two">
                  <h4>Ideation</h4>
                  <p>
                    Our 2D artists experiment and draw out multiple ideas that
                    can be implemented. The goal of this stage is to brainstorm
                    and find different styles in which the same piece of artwork
                    can be represented.
                  </p>
                </div>
                <div className="step-three">
                  <h4>Refinement</h4>
                  <p>
                    We improve the ideas we have developed, refining them in
                    multiple aspects, such as aesthetics and functionality. Our
                    artists spend multiple rounds of iteration to produce the
                    artwork step by step.
                  </p>
                </div>
                <div className="step-four">
                  <h4>Color Implementation</h4>
                  <p>
                    Our 2D artists implement the colors into the artworks after
                    careful analysis and deliberation on color theory. We select
                    the colors most apt to bring out the intent of the artwork
                    inside each game sequence.
                  </p>
                </div>
                <div className="step-five">
                  <h4>Creation of Draft</h4>
                  <p>
                    Our team creates a final draft of the artwork. For this,
                    each artist makes sure the artwork passes the internal
                    quality metrics we set prior to the art production pipeline
                    has commenced.
                  </p>
                </div>
                <div className="step-six">
                  <h4>Review and Iteration</h4>
                  <p>
                    Once the final draft is submitted, our art directors conduct
                    a comprehensive review of each artwork. We provide
                    actionable feedback to further improve the quality of the
                    art, and the artists make iterations based on this.
                  </p>
                </div>
                <h3>THE 2D GAME ART DEVELOPMENT</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTopButton />
    </>
  );
};

export default Contact;
