import React, { useEffect } from 'react';
import * as THREE from 'three';

const Globe = () => {

    useEffect(() => {
        const container = document.getElementById('threeGlobe');

        // Setup Scene
        const scene = new THREE.Scene();

        // Setup Renderer
        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setClearColor(0x000000, 0);
        renderer.setSize(container.offsetHeight, container.offsetHeight);
        scene.background = null;
        container.appendChild(renderer.domElement);

        // Setup Lights
        var light1 = new THREE.PointLight(0x5a54ff, 0.75);
        light1.position.set(-150, 150, -50);

        var light2 = new THREE.PointLight(0x4158f6, 0.75);
        light2.position.set(-400, 200, 150);

        var light3 = new THREE.PointLight(0x803bff, 0.7);
        light3.position.set(100, 250, -100);

        scene.add(light1, light2, light3);

        // Setup Geometry
        const atmosphereShader = {
            'atmosphere': {
                uniforms: {},
                vertexShader: [
                    'varying vec3 vNormal;',
                    'void main() {',
                    'vNormal = normalize( normalMatrix * normal );',
                    'gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
                    '}'
                    ].join('\n'),
                    fragmentShader: [
                    'varying vec3 vNormal;',
                    'void main() {',
                    'float intensity = pow( 0.99 - dot( vNormal, vec3( 0, 0, 1.0 ) ), 6.0 );',
                    'gl_FragColor = vec4( .28, .48, 1.0, 1.0 ) * intensity;',
                    '}'
                ].join('\n')
            }
        };
        const atmosphereGeometry = new THREE.SphereGeometry(0, 64, 64);

        const atmosphereMaterial = new THREE.ShaderMaterial({
            uniforms: THREE.UniformsUtils.clone(atmosphereShader['atmosphere'].uniforms),
            vertexShader: atmosphereShader['atmosphere'].vertexShader,
            fragmentShader: atmosphereShader['atmosphere'].fragmentShader,
            side: THREE.BackSide,
            blending: THREE.AdditiveBlending,
            transparent: true
        });
        const atm = new THREE.Mesh(atmosphereGeometry, atmosphereMaterial);
        atm.scale.set(1.05, 1.05, 1.05);
        scene.add(atm);

        atm.position.set(-.1, .1, 0);

        // Setup Globe
        const sphereGeometry = new THREE.SphereGeometry(2, 64, 64);
        const sphereMaterial = new THREE.MeshLambertMaterial({
            color: 0xffffff
        });
        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        // sphere.castShadow = true;
        // sphere.receiveShadow = true;
        scene.add(sphere);
        

        // Setup Map Overlay
        const loader = new THREE.TextureLoader();
        const overlayMaterial = new THREE.MeshBasicMaterial({
            map: loader.load('/images/png/globe.webp'),
            transparent: true
        });
        
        const overlaySphereGeometry = new THREE.SphereGeometry(2.003, 64, 64);
       
        const overlaySphere = new THREE.Mesh(overlaySphereGeometry, overlayMaterial);
       
        overlaySphere.castShadow = true;
        overlaySphere.receiveShadow = true;
        sphere.add(overlaySphere);

        // Setup Bezier Curves
        var numPoints = 100;
        var start = new THREE.Vector3(-0.8, 1.5, 1.3);
        var middle = new THREE.Vector3(.6, .6, 3.2);
        var end = new THREE.Vector3(1.5, -1, .8);

        var curveQuad = new THREE.QuadraticBezierCurve3(start, middle, end);

        var tube1 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);
        const tubeMaterial = new THREE.MeshBasicMaterial({
            color: 0x62D647
        });
        tube1.setDrawRange(0, 10000);
        var curveMesh1 = new THREE.Mesh(tube1, tubeMaterial);
        sphere.add(curveMesh1);

        var tube2 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);
        const tubeMaterialRed = new THREE.MeshBasicMaterial({
            color: 0xFD3944
        });
        tube2.setDrawRange(0, 10000);
        var curveMesh2 = new THREE.Mesh(tube2, tubeMaterialRed);
        sphere.add(curveMesh2);
        curveMesh2.rotation.y = 0;
        curveMesh2.rotation.z = .75;
        curveMesh2.rotation.x = -.1;

        var tube3 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);
        var tube4 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);
        var tube5 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);
        var tube6 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);
        var tube7 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);
        var tube8 = new THREE.TubeGeometry(curveQuad, numPoints, 0.01, 20, false);

        // Detect Click Drag Rotation
        var isDragging = false;
        var previousMousePosition = {
            x: 0,
            y: 0
        };
        const MAX_ROTATION_X = Math.PI / 6;
        const MIN_ROTATION_X = -Math.PI / 6;

        const onTouchStart = (e) => {
            e.preventDefault();
            isDragging = true;
            previousMousePosition = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            };
        };

        const onTouchMove = (e) => {
            e.preventDefault();
            if (isDragging) {
                var deltaMove = {
                    x: e.touches[0].clientX - previousMousePosition.x,
                    y: e.touches[0].clientY - previousMousePosition.y
                };
                sphere.rotation.y += deltaMove.x * 0.004;
                let newRotationX = sphere.rotation.x + deltaMove.y * 0.004;
                sphere.rotation.x = Math.max(MIN_ROTATION_X, Math.min(MAX_ROTATION_X, newRotationX));
                previousMousePosition = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                };
            }
        };

        const onTouchEnd = (e) => {
            e.preventDefault();
            isDragging = false;
        };

        container.addEventListener('mousedown', function(e) {
            isDragging = true;
            previousMousePosition = {
                x: e.offsetX,
                y: e.offsetY
            };
        });

        container.addEventListener('mousemove', function(e) {
            if (isDragging) {
                var deltaMove = {
                    x: e.offsetX - previousMousePosition.x,
                    y: e.offsetY - previousMousePosition.y
                };
                sphere.rotation.y += deltaMove.x * 0.004;
                let newRotationX = sphere.rotation.x + deltaMove.y * 0.004;
                sphere.rotation.x = Math.max(MIN_ROTATION_X, Math.min(MAX_ROTATION_X, newRotationX));
                previousMousePosition = {
                    x: e.offsetX,
                    y: e.offsetY
                };
            }
        });

        document.addEventListener('mouseup', function() {
            isDragging = false;
        });

        container.addEventListener('mouseout', function() {
            isDragging = false;
        });

        container.addEventListener('touchstart', onTouchStart);
        container.addEventListener('touchmove', onTouchMove);
        container.addEventListener('touchend', onTouchEnd);

        // Create Sprites For Images
        const imageTextures = [
            '/images/png/globe-img2.webp',
            '/images/png/globe-img3.webp',
            '/images/png/globe-img4.webp',
            '/images/png/globe-img5.webp'
        ];
        const imageSprites = [];
        const radius = 2.4;
        imageTextures.forEach((textureUrl, index) => {
            const texture = loader.load(textureUrl);
            const spriteMaterial = new THREE.SpriteMaterial({ map: texture, sizeAttenuation: true });
            const sprite = new THREE.Sprite(spriteMaterial);
            sprite.scale.set(0.6, 0.3, 1);
            const angle = (index / imageTextures.length) * Math.PI * 1;
            sprite.position.set(Math.cos(angle) * radius, 0, Math.sin(angle) * radius);
            sphere.add(sprite);
            imageSprites.push(sprite);
        });
        const spriteVisibility = () => {
            const isSmallScreen = window.innerWidth <= 575;
            imageSprites.forEach(sprite => {
                sprite.visible = !isSmallScreen;
            });
        };
        spriteVisibility();
        window.addEventListener('resize', spriteVisibility);
        

        // Setup Camera
        const camera = new THREE.PerspectiveCamera(75, 900 / 900, 0.1, 100);
        camera.position.z = 5;

        var renderCount = 0;
        var currentGrowing = 0;
        var tubes = [tube1, tube2, tube3, tube4, tube5, tube6, tube7, tube8];

        function GrowTube(index, renderCount) {
            renderCount = Math.ceil(renderCount / 3) * 3;
            tubes[index].setDrawRange(0, renderCount);
            if (index > 2) {
                tubes[index - 3].setDrawRange(renderCount, 10000);
            } else {
                tubes[(tubes.length - 3) + index].setDrawRange(renderCount, 10000);
            }
        }

        // Animation Loop
        const animate = function() {
            if (renderCount < 10000) {
                renderCount += 80;
                GrowTube(currentGrowing, renderCount);
            } else {
                renderCount = 0;
                if (currentGrowing >= tubes.length - 1) {
                    currentGrowing = 0;
                } else {
                    currentGrowing++;
                }
            }
            requestAnimationFrame(animate);
            if (!isDragging) {
                sphere.rotation.y += 0.0005;
            }
            renderer.render(scene, camera);
        };
        animate();

        return () => {
            container.removeChild(renderer.domElement);
            window.removeEventListener('resize', spriteVisibility);
        };
    }, []);

    return (
        <div id="threeGlobe"></div>
    )
};

export default Globe;