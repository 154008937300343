import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link , useLocation } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackToTopButton from "../components/BackToTopButton";
import CustomCursor from "../components/CustomCursor";
import SectionHeading from "../components/SectionHeading";
import Counter from "../components/Counter";
import Globe from "../components/Globe";
import Lottie from "react-lottie";
import animationData from "../assets/json/company-journey.json";
import resAnimationData from "../assets/json/res-company-journey.json";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebaseConfig';

///import { logEvent } from '../firebaseConfig';
const Home = () => {
  const animationRef = useRef(null);
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  const resAnimationRef = useRef(null);
  const resAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: resAnimationData,
  };

  const [smallSize, setSmallSize] = useState(false);
  // useEffect(() => {
  //   logEvent('VG_HM', { page_path: '/' });
  // }, []);
  useEffect(() => {
    const setBackgroundImageBasedOnScreenSize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 767) {
        setSmallSize(true);
      } else {
        setSmallSize(false);
      }
    };
    setBackgroundImageBasedOnScreenSize();
    window.addEventListener("resize", setBackgroundImageBasedOnScreenSize);
    return () => {
      window.removeEventListener("resize", setBackgroundImageBasedOnScreenSize);
    };
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const handleAfterChange = (current) => {
    setCurrentSlide(current);
  };
  const slider1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: "linear",
    afterChange: handleAfterChange,
  };
  const slider2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const slider3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    vertical: true,
    centerMode: true,
  };
  const slider4 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);

  const [isPlaying2, setIsPlaying2] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const playPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  const playPause1 = () => {
    const video = videoRef1.current;
    if (video.paused) {
      video.play();
      setIsPlaying1(true);
    } else {
      video.pause();
      setIsPlaying1(false);
    }
  };
  const playPause2 = () => {
    const video = videoRef2.current;
    if (video.paused) {
      video.play();
      setIsPlaying2(true);
    } else {
      video.pause();
      setIsPlaying2(false);
    }
  };

  const handleMouseEnter = (setHover) => {
    setHover(true);
  };

  const handleMouseLeave = (setHover) => {
    setHover(false);
  };

  useEffect(() => {
    const createObserver = (videoElement, setPlaying, setHovered) => {
      const containerElement = videoElement.parentElement;
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              videoElement.play().catch((error) => {
                console.log("Autoplay was prevented:", error);
              });
              setPlaying(true);
            } else {
              videoElement.pause();
              setPlaying(false);
            }
          });
        },
        { threshold: 0.5 } // Adjust this value as needed
      );

      if (containerElement) {
        observer.observe(containerElement);
      }

      return () => {
        if (containerElement) {
          observer.unobserve(containerElement);
        }
      };
    };

    const cleanUpVideo1 = createObserver(
      videoRef.current,
      setIsPlaying,
      setIsHovered
    );
    const cleanUpVideo2 = createObserver(
      videoRef1.current,
      setIsPlaying1,
      setIsHovered1
    );
    const cleanUpVideo3 = createObserver(
      videoRef2.current,
      setIsPlaying2,
      setIsHovered2
    );

    return () => {
      cleanUpVideo1();
      cleanUpVideo2();
      cleanUpVideo3();
    };
  }, []);

  const slides = [
    {
      title: "CUSTOM 2D & 3D MOBILE GAME STUDIO",
      description:
        "We are a leading game development outsourcing studio whose expertise spans a myriad of domains such as Unity, Unreal, AI, AR/VR, 2D Game Design, 3D Modeling, 2D/3D Animation, and more. We consistently deliver high-quality results for our global clients.",
      image: "/images/webp/slider1.webp",
    },
    {
      title: "TOP ROBLOX GAME ART STUDIO",
      description:
        "Vasundhara Game has a large pool of experienced game artists, modelers, animators, and UI/UX experts. Our team is equipped with the necessary skills and expertise to manage the game art production process for a variety of genres and styles for both 2D & 3D art.",
      image: "/images/webp/slider2.webp",
    },
    {
      title: "UNITY GAME DEVELOPMENT COMPANY",
      description:
        "We're Unity experts crafting stunning games across genres and platforms with our powerful Unity game development services. Leverage our expertise to breathe life into your game concepts with innovative features, exceptional design, and captivating graphics.",
      image: "/images/webp/slider3.webp",
    },
    {
      title: "CUSTOM 3D CHARACTER DESIGN STUDIO",
      description:
        "We specialize in crafting tailored 3D character designs that meet our clients' specific needs. Our talented 3D character designers have created unique and captivating characters for many popular games.",
      image: "/images/webp/slider4.webp",
    },
    {
      title: "VR/XR SIMULATION GAME",
      description:
        "Our game design studio comprises an experienced team adept at conceptualizing and creating interactive applications. We gain an in-depth understanding of client requirements and execute the project through our tried-and-tested process, ensuring a quality application.",
      image: "/images/webp/slider5.webp",
    },
    {
      title: "3D ANIMATION & RIGGING SERVICE",
      description:
        "Transform imagination into reality with our 3D animation services. Our team of experienced animators creates lifelike characters, props, and environments using the best 3D animation tools and visual effects, meeting even the most intricate project requirements.",
      image: "/images/webp/slider6.webp",
    },
  ];

  const clients = [
    {
      description:
        "“Working with Bansi was great! Despite some backend issues on our side, she remained patient and collaborated effectively to complete the app.”",
      name: "Yepi Susanti",
      designation: "Project Manager at MRC Ventures Pte Ltd, Singapore",
      image: "/images/png/client-review.webp",
      link: "https://www.upwork.com/freelancers/~01ca6fe600083be89c", // Add the link here if available
      logo: "/images/png/upwork.webp",
    },
    {
      description:
        "“Vasundhara’s exceptional service and dedication consistently exceed our expectations. We value our partnership and look forward to future success together.”",
      name: "Pedro Lama",
      designation: "Entrepreneur & Singer, Russia",
      image: "/images/png/client-review1.webp",
      link: "https://www.freelancer.in/u/vasundhara19?review_context_id=26118652&review_type=project&sb=t", // Add the link here if available
      logo: "/images/png/freelancer.webp",
    },
    {
      description:
        "“Vasundhara Infotech LLP has boosted our productivity and expanded our development capabilities. Their consistently high-quality work has truly impressed us.”",
      name: "Oreen & Nitzan",
      designation: "Mobile Brain LTD, Israel",
      image: "/images/png/client-review2.webp",
      link: "https://clutch.co/go-to-review/d48a7df9-34f5-4632-95da-54975ce9a46e/147740", // Add the link here if available
      logo: "/images/png/clutch.webp",
    },
    {
      description:
        "“It is always a pleasure working with Vasudhara Infotech. They are a responsive, skilled, and reliable team!!”",
      name: "Steve Karigo",
      designation: "Author, Speaker, Consultant Optima Branding, United States",
      image: "/images/png/client-review3.webp",
      link: "https://www.freelancer.in/u/vasundhara19?review_context_id=34667149&review_type=project&sb=t", // Add the link here if available
      logo: "/images/png/freelancer.webp",
    },
    // Add more slides as needed
  ];

  const location = useLocation();
  const handleClick = (page) => {
    const fromPage = location.pathname;

    // Map the page paths to custom event strings
    const pageMappings = {
        "/": "VG_HM",
    };

    // Map the transition strings
    const transitionMappings = {
        "/": {
            "/contact": "VG_HM_CU",
        }
    };

    // Get the event string for the transition
    const pageString = transitionMappings[fromPage]?.[page] || pageMappings[page] || "UNKNOWN";

   // console.log(`From Page: ${fromPage}, Visit String: ${pageString}`);

    if (analytics) {
        logEvent(analytics, pageString, {
            button_name: "LET'S GET CONNECT & SEE MORE",
            page_title: document.title,
        });
    }

    // Navigate to the new page after logging the event
    // navigate(page);
};

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://vasundharagames.com" />
        <title>Game Development Studio | Game Studio | Vasundhara Games</title>
      </Helmet>
      <CustomCursor />
      <Header />
      {/* <section className="home-slider">
        <Slider {...slider1}>
          {[...Array(6)]?.map((item, i) => (
            <div key={i + 1}>
              <div
                style={{
                  backgroundImage: `url(${
                    smallSize
                      ? `images/webp/slider-sm${i + 1}.webp`
                      : `images/webp/slider${i + 1}.webp`
                  })`,
                }}
                className="slider-img"
              >
                <div className="container">
                  <div className="slider-content">
                    <h1>Custom 2D & 3D Mobile Game Studio</h1>
                    <p>
                      We are a leading game development outsourcing studio whose
                      expertise spans a myriad of domains such as Unity, Unreal
                      AI, AR/VR, 2D Game Design, 3D Modeling, 2D/3D Animation,
                      Etc. We consistently deliver high-quality results for our
                      global clientele.
                    </p>
                    <Link to="/contact">
                      <button className="btn btn-outline d-flex align-items-center gap-2">
                        LET'S GET CONNECT
                        <img src="/images/svg/arrow-right.svg" alt="arrow" />
                      </button>
                    </Link>
                    <div className="slider-pagination">
                      <h5>
                        {currentSlide + 1}
                        <span>/6</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section> */}
      <section className="home-slider">
        <Slider {...slider1}>
          {slides.map((slide, i) => (
            <div key={i}>
              <div
                style={{
                  backgroundImage: `url(${
                    smallSize
                      ? `images/webp/slider-sm${i + 1}.webp`
                      : slide.image
                  })`,
                }}
                className="slider-img"
              >
                <div className="container">
                  <div className="slider-content">
                    <h1 data-title={slide.title}>{slide.title}</h1>
                    <p>{slide.description}</p>
                    <Link to="/contact" onClick={() => handleClick("/contact")}>
                      <button className="btn btn-outline d-flex align-items-center gap-2">
                        LET'S GET CONNECT
                        <img src="/images/svg/arrow-right.svg" alt="arrow" />
                      </button>
                    </Link>
                    <div className="slider-pagination">
                      <h5>
                        {i + 1}
                        <span>/6</span>{" "}
                        {/* Adjust the total number as needed */}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </section>
      <section className="about-us section-py light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <h2>
                Discover why millions of client choose <span>vasundhara</span>{" "}
                for their business
              </h2>
            </div>
            <div className="col-sm-12">
              <div className="row align-items-sm-center">
                <div className="col-lg-3 col-6">
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about1.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={10} />+ Years
                      </h5>
                      <span>In Industry</span>
                    </div>
                  </div>
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about2.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={2} />x
                      </h5>
                      <span>Growth Rate</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-lg-0 order-first">
                  <div className="globe-container">
                    <Globe />
                    <div className="client-slider d-sm-none">
                      <div className="client-detail">
                        <img src="/images/png/globe-img2.webp" alt="client" />
                        <img src="/images/png/globe-img3.webp" alt="client" />
                        <img src="/images/png/globe-img4.webp" alt="client" />
                        <img src="/images/png/globe-img5.webp" alt="client" />
                      </div>
                      <div className="client-detail">
                        <img src="/images/png/globe-img2.webp" alt="client" />
                        <img src="/images/png/globe-img3.webp" alt="client" />
                        <img src="/images/png/globe-img4.webp" alt="client" />
                        <img src="/images/png/globe-img5.webp" alt="client" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about3.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={800} />+
                      </h5>
                      <span>Projects Delivered</span>
                    </div>
                  </div>
                  <div className="about-content d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <img
                        src="/images/png/about4.webp"
                        className="img-fluid"
                        alt="about"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5>
                        <Counter target={300} />+
                      </h5>
                      <span>Expert Professionals</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-11">
              <p className="text-center mb-0">
                We focus on providing game development services to create
                projects that stand out and stay in memory for long. Our game
                developers have extensive experience enabling us to help global
                gaming companies with their popular titles.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="our-services section-py">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-lg-10 section-heading">
              <SectionHeading title="Our Services" image="LightImage" />
              <p className="mb-0">
                Vasundhara Game Studio is a top-notch game development studio
                offering consistently superior services to clients. We customize
                our approach to the requirements of each project. Our
                comprehensive game development team capable of strategic
                execution for diverse projects.
              </p>
            </div>
            <div className="col-sm-12 mb-5">
              <div className="row align-items-center gy-lg-0 gy-4">
                <div className="col-xl-5 col-lg-6">
                  <h3>2D/3D Design Art</h3>
                  <p>
                    As one of the leading software development companies, we at
                    Vasundhara offer a diverse range of services to our clients,
                    operating in these core areas.
                  </p>
                  <ul>
                    <li>2D Game UI/UX Design</li>
                    <li>3D Modeling</li>
                    <li>2D Character Design</li>
                  </ul>
                  <Link to="/contact" onClick={() => handleClick("/contact")}>
                  <button className="btn btn-outline">
                    See More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                  </button></Link>
                </div>
                <div className="col-xl-7 col-lg-6 order-lg-0 order-first">
                  <div
                    className="services-video ratio ratio-16x9"
                    onMouseEnter={() => handleMouseEnter(setIsHovered)}
                    onMouseLeave={() => handleMouseLeave(setIsHovered)}
                  >
                    {!isPlaying && (
                      <div
                        id="playBtn"
                        onClick={playPause}
                        className="play-btn"
                      >
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    )}
                    {isPlaying && isHovered && (
                      <div
                        id="playBtn"
                        onClick={playPause}
                        className="play-btn"
                      >
                        <FontAwesomeIcon icon={faPause} />
                      </div>
                    )}
                    <video
                      ref={videoRef}
                      onEnded={() => setIsPlaying(false)}
                      muted
                    >
                      <source src="/videos/video.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-5">
              <div className="row align-items gy-lg-0 gy-4">
                <div className="col-xl-7 col-lg-6 order-lg-0 order-first">
                  <div
                    className="services-video-center ratio ratio-16x9"
                    onMouseEnter={() => handleMouseEnter(setIsHovered1)}
                    onMouseLeave={() => handleMouseLeave(setIsHovered1)}
                  >
                    {!isPlaying1 && (
                      <div
                        id="playBtn"
                        onClick={playPause1}
                        className="play-btn"
                      >
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    )}
                    {isPlaying1 && isHovered1 && (
                      <div
                        id="playBtn"
                        onClick={playPause1}
                        className="play-btn"
                      >
                        <FontAwesomeIcon icon={faPause} />
                      </div>
                    )}
                    <video
                      ref={videoRef1}
                      onEnded={() => setIsPlaying1(false)}
                      muted
                    >
                      <source src="/videos/video1.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <h3>2D/3D Animation</h3>
                  <p>
                    Our animation company excels in 2D and 3D animation, making
                    us the ideal choice for outsourcing your animation needs. We
                    bring characters, environments, and props to life with
                    industry-standard quality and the latest trends.
                  </p>
                  <ul>
                    <li>3D Animation & Rigging</li>
                    <li>2D Animation</li>
                    <li>2D/3D Animation</li>
                  </ul>
                  <Link to="/contact" onClick={() => handleClick("/contact")}>
                  <button className="btn btn-outline">
                    See More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                  </button></Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mb-5">
              <div className="row align-items-center gy-lg-0 gy-4">
                <div className="col-xl-5 col-lg-6">
                  <h3>Game Developement</h3>
                  <p>
                    We develop stunning games for a wide variety of platforms.
                    Our expertise extends to numerous genres such as RPG,
                    MMORPG, Casual, Hyper-Casual, Card, and more.
                  </p>
                  <ul>
                    <li>Unity 3D Game Development</li>
                    <li>AR-VR Game Development</li>
                    <li>Mobile Game Development</li>
                  </ul>
                  <Link to="/contact" onClick={() => handleClick("/contact")}>
                  <button className="btn btn-outline">
                    See More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                  </button></Link>
                </div>
                <div className="col-xl-7 col-lg-6 order-lg-0 order-first">
                  <div
                    className="services-video ratio ratio-16x9"
                    onMouseEnter={() => handleMouseEnter(setIsHovered2)}
                    onMouseLeave={() => handleMouseLeave(setIsHovered2)}
                  >
                    {!isPlaying2 && (
                      <div
                        id="playBtn"
                        onClick={playPause2}
                        className="play-btn"
                      >
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    )}
                    {isPlaying2 && isHovered2 && (
                      <div
                        id="playBtn"
                        onClick={playPause2}
                        className="play-btn"
                      >
                        <FontAwesomeIcon icon={faPause} />
                      </div>
                    )}
                    <video
                      ref={videoRef2}
                      onEnded={() => setIsPlaying2(false)}
                      muted
                    >
                      <source src="/videos/video2.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-we section-py light-bg">
        <div className="container">
          <div className="row align-items-center gy-lg-0 gy-4">
            <div className="col-lg-6">
              <img
                src="/images/png/why-we.webp"
                className="img-fluid w-100"
                alt="why-we"
              />
            </div>
            <div className="col-lg-6">
              <span className="badge">Why We</span>
              <h2>We make it effortlessly to track all user analytics</h2>
              <p>
                Vasundhara Game Studios is a reliable game development partner
                with many years of experience in providing game development
                services to clients in the USA, UK, UAE, India, and other parts
                of the world. Our standards are on par with the top game and app
                development companies and technology ventures in the industry
                today.
              </p>
              <div className="row gx-xxl-5 gy-xxl-4 gy-3">
                <div className="col-sm-6">
                  <div className="why-we-content">
                    <img
                      src="/images/png/why-icon1.webp"
                      className="img-fluid"
                      alt="why-icon"
                    />
                    <h6>Innovation</h6>
                    <p>
                      We believe in innovating and meeting customer needs in
                      every project.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="why-we-content">
                    <img
                      src="/images/png/why-icon2.webp"
                      className="img-fluid"
                      alt="why-icon"
                    />
                    <h6>Quality-Focused</h6>
                    <p>
                      We consistently focus on quality over quantity - that also
                      makes us credible as a software company.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="why-we-content">
                    <img
                      src="/images/png/why-icon3.webp"
                      className="img-fluid"
                      alt="why-icon"
                    />
                    <h6>Efficiency</h6>
                    <p>
                      Vasundhara provides services at affordable and competitive
                      rates with fast delivery.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="why-we-content">
                    <img
                      src="/images/png/why-icon4.webp"
                      className="img-fluid"
                      alt="why-icon"
                    />
                    <h6>Fraud Detection</h6>
                    <p>
                      Expert game development services ensuring top-notch
                      security and reliability..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-review section-py">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 section-heading">
              <SectionHeading
                title="What Our Client Think"
                image="LightImage"
              />
            </div>
            <div className="col-sm-12">
              <Slider {...slider2}>
                {clients.map((slide, i) => (
                  <div key={i + 1}>
                    <div className="row align-items-center">
                      <div className="col-lg-7">
                        <p>
                          {slide.description}
                          {/* Add extra clickable logo */}
                          <a
                            href={slide.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="extra-logo"
                          >
                            <img
                              src={slide.logo}
                              alt="Extra Logo"
                              style={{
                                marginTop: "10px",
                                maxWidth: "15%", // Set maximum width
                                height: "auto", // Maintain aspect ratio
                              }}
                              // width="100" // Optional: This is the default width, which can be overridden by maxWidth
                              className="img-fluid"
                            />
                            {/* <img
                              src={slide.logo}
                              alt="Extra Logo"
                              style={{ marginTop: "10px" }}
                              width="100" // Set width in pixels
                              height="auto" // Maintain aspect ratio (can be omitted if you set only width)
                              className="img-fluid"
                            /> */}
                          </a>
                        </p>
                        <h6>{slide.name}</h6>
                        <span>{slide.designation}</span>
                      </div>
                      <div className="col-lg-5 client-img">
                        <img
                          src={slide.image}
                          className="img-fluid"
                          alt="client"
                        />
                        <div className="img-effect"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="client-review section-py">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 section-heading">
              <SectionHeading
                title="What Our Client Think"
                image="LightImage"
              />
            </div>
            <div className="col-sm-12">
              <Slider {...slider2}>
                {/* {[...Array(5)]?.map((item, i) => ( 
                {clients.map((slide, i) => (
                  <div key={i + 1}>
                    <div className="row align-items-center">
                      <div className="col-lg-7">
                        <p>{slide.description}</p>
                        <h6>
                          <a
                            href={slide.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "inherit", textDecoration: "none" }}
                          >
                            {slide.name}
                          </a>
                        </h6>
                        <span>{slide.designation}</span>
                      </div>
                      <div className="col-lg-5 client-img">
                        <img
                          src={slide.image}
                          className="img-fluid"
                          alt="client"
                        />
                        <div className="img-effect"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section> */}
      <section className="blog-list section-py light-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 section-heading">
              <SectionHeading title="Blog" />
            </div>
            <div className="col-sm-12 d-lg-block d-none">
              <div className="row">
                <div className="col-lg-4">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="/images/png/blog1.webp"
                        className="img-fluid"
                        alt="blog"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src="/images/svg/calendar.svg"
                        className="img-fluid"
                        alt="calendar"
                      />
                      <span>June 6, 2024</span>
                    </div>
                    <h5>Best Software Development Trends to Follow in 2024</h5>
                    <p>
                      The growth of software development in 2024 is ongoing and
                      will persist in the months ahead. We would presumably
                      observe many developments...
                    </p>
                    <Link to="https://vasundhara.io/blogs/best-software-development-trends-in-2024">
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="/images/png/blog2.webp"
                        className="img-fluid"
                        alt="blog"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src="/images/svg/calendar.svg"
                        className="img-fluid"
                        alt="calendar"
                      />
                      <span>June 4, 2024</span>
                    </div>
                    <h5>Why Choose Unity 3D For Game Development Project?</h5>
                    <p>
                      Unity 3D game development is a rapidly growing technology
                      in the field of game development. The programming
                      environment is user-friendly...
                    </p>
                    <Link to="https://vasundhara.io/blogs/unity-3d-game-development-project">
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog-card">
                    <div className="blog-img">
                      <img
                        src="/images/png/blog1.webp"
                        className="img-fluid"
                        alt="blog"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src="/images/svg/calendar.svg"
                        className="img-fluid"
                        alt="calendar"
                      />
                      <span>May 31, 2024</span>
                    </div>
                    <h5>
                      How AI is Transforming E-commerce Website Development
                    </h5>
                    <p>
                      The concept of artificial intelligence (AI) has recently
                      gained widespread attention and is now ubiquitous,
                      transcending the realm of frightening futuristic...
                    </p>
                    <Link to="https://vasundhara.io/blogs/aI-transforming-e-commerce-website-development">
                      Read More
                      <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 d-lg-none">
              <Slider {...slider4}>
                <div className="blog-card">
                  <div className="blog-img">
                    <img
                      src="/images/png/blog1.webp"
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src="/images/svg/calendar.svg"
                      className="img-fluid"
                      alt="calendar"
                    />
                    <span>June 6, 2024</span>
                  </div>
                  <h5>Best Software Development Trends to Follow in 2024</h5>
                  <p>
                    The growth of software development in 2024 is ongoing and
                    will persist in the months ahead. We would presumably
                    observe many developments...
                  </p>
                  <Link to="https://vasundhara.io/blogs/best-software-development-trends-in-2024">
                    Read More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
                <div className="blog-card">
                  <div className="blog-img">
                    <img
                      src="/images/png/blog2.webp"
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src="/images/svg/calendar.svg"
                      className="img-fluid"
                      alt="calendar"
                    />
                    <span>June 4, 2024</span>
                  </div>
                  <h5>Why Choose Unity 3D For Game Development Project?</h5>
                  <p>
                    Unity 3D game development is a rapidly growing technology in
                    the field of game development. The programming environment
                    is user-friendly...
                  </p>
                  <Link to="https://vasundhara.io/blogs/unity-3d-game-development-project">
                    Read More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
                <div className="blog-card">
                  <div className="blog-img">
                    <img
                      src="/images/png/blog1.webp"
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src="/images/svg/calendar.svg"
                      className="img-fluid"
                      alt="calendar"
                    />
                    <span>May 31, 2024</span>
                  </div>
                  <h5>How AI is Transforming E-commerce Website Development</h5>
                  <p>
                    The concept of artificial intelligence (AI) has recently
                    gained widespread attention and is now ubiquitous,
                    transcending the realm of frightening futuristic...
                  </p>
                  <Link to="https://vasundhara.io/blogs/aI-transforming-e-commerce-website-development">
                    Read More
                    <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                  </Link>
                </div>
              </Slider>
            </div>
            <div className="col-lg-12 text-center">
              <Link to="https://vasundhara.io/blogs">
                <button className="btn btn-primary">
                  Read All Blogs
                  <FontAwesomeIcon icon={faAngleRight} className="ms-2" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section-py">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 section-heading">
              <SectionHeading title="Company Journey" image="LightImage" />
              <p>
                Vasundhara Game Studios is a reliable game development partner,
                having solid experience of many years in providing game
                development services to clients in the USA, UK, UAE, India, and
                other parts of the world. Our standards are on partner with the
                top game and app development companies and technology ventures
                in the industry today.
              </p>
            </div>
            <div className="col-sm-12">
              <div className="journey-animation d-lg-block d-none">
                <Lottie
                  options={animationOptions}
                  ref={animationRef}
                  isClickToPauseDisabled={true}
                />
              </div>
              <div className="journey-animation d-lg-none">
                <Lottie
                  options={resAnimationOptions}
                  ref={resAnimationRef}
                  isClickToPauseDisabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mini-banner section-py">
        <div className="container">
          <div className="row align-items-center gy-lg-0 gy-4">
            <div className="col-lg-7 pe-xl-5">
              <h2>Explore Digital Innovations</h2>
              <p>
                We offer robust product strategies, empirical market research,
                intuitive UX design, and reduced time to the market launch
                process.
              </p>
              <Link to="/contact" onClick={() => handleClick("/contact")}>
                <button className="btn btn-primary">Get Started</button>
              </Link>
            </div>
            <div className="col-lg-5 ps-xl-5">
              <Slider {...slider3}>
                <div>
                  <span>Game Development</span>
                </div>
                <div>
                  <span>Web Development</span>
                </div>
                <div>
                  <span>Mobile App</span>
                </div>
                <div>
                  <span>Design</span>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTopButton />
    </>
  );
};

export default Home;
