import React, { useRef, useEffect } from 'react';

const CustomCursor = () => {
    const cursorRef = useRef(null);
    useEffect(() => {
        const cursor = cursorRef.current;
        const handleMouseMove = (e) => {
            if (cursor) {
                cursor.style.top = `${e.clientY - 10}px`;
                cursor.style.left = `${e.clientX - 10}px`;
                cursor.style.opacity = '1';
            }
        };
        const handleMouseLeave = () => {
            if (cursor) {
                cursor.style.opacity = '0';
            }
        };
        const handleMouseEnter = () => {
            if (cursor) {
                cursor.style.opacity = '1';
            }
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseleave', handleMouseLeave);
        document.addEventListener('mouseenter', handleMouseEnter);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseleave', handleMouseLeave);
            document.removeEventListener('mouseenter', handleMouseEnter);
        };
    }, []);
  
    return (
        <div className="custom-cursor" ref={cursorRef}></div>
    );
};

export default CustomCursor;