import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SectionHeading from "../components/SectionHeading";
import CustomCursor from "../components/CustomCursor";
import BackToTopButton from "../components/BackToTopButton";
import ContectCompo from "../components/ContectCompo";
import UnderDevelopment from "../components/UnderDevelopment";
//import { logEvent } from '../firebaseConfig';

const Portfolio = () => {
  // useEffect(() => {
  //   logEvent('VG_PR', { page_path: '/portfolio' });
  // }, []);
  return (
    <>
      <Helmet>
      <link rel="canonical" href="https://vasundharagames.com/portfolio" />
        <title>Vasundhara Game Studio | Portfolio</title>
      </Helmet>
      <CustomCursor />
      <Header />
      <section className="our-portfolio light-bg">
        <div className="mt-2">
          <UnderDevelopment />

        </div>
      </section>
      <section className="our-portfolio section-py light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 section-heading">
              <SectionHeading title="Our Portfolio" />
            </div>
            <div className="col-sm-10">
              <Tabs
                defaultActiveKey="2d-design"
                className="justify-content-center align-items-center"
              >
                <Tab eventKey="2d-design" title="2D Design Art">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="action-game">
                        <Tab eventKey="action-game" title="Action Game Design">
                          <h3>Action Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="board-game" title="Board Game Design">
                          <h3>Board Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="card-game" title="Card Game Design">
                          <h3>Card Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="hyper-game"
                          title="Hyper Casual Game Design"
                        >
                          <h3>Hyper Casual Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="casual-game" title="Casual Game Design">
                          <h3>Casual Game Design</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="3d-design" title="3D Design Art">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="stylized-character">
                        <Tab
                          eventKey="stylized-character"
                          title="Stylized 3D Character"
                        >
                          <h3>Stylized 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="highpoly-character"
                          title="Highpoly 3D Character"
                        >
                          <h3>Highpoly 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="lowpoly-character"
                          title="Lowpoly 3D Character"
                        >
                          <h3>Lowpoly 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="cartoon-character"
                          title="Cartoon 3D Character"
                        >
                          <h3>Cartoon 3D Character</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="3d-environment" title="3D Environment">
                          <h3>3D Environment</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="2d-animation" title="2D Animation">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="animation-2d">
                        <Tab eventKey="animation-2d" title="2D Animation">
                          <h3>2D Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="motion-graphics" title="Motion Graphics">
                          <h3>Motion Graphics</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="spine-animation" title="Spine Animation">
                          <h3>Spine Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="2d-character-animation"
                          title="2D Character Animation"
                        >
                          <h3>2D Character Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="3d-animation" title="3D Animation">
                  <div className="portfolio-tab">
                    <img
                      src="/images/png/portfolio-img1.webp"
                      className="img-fluid"
                      alt="portfolio"
                    />
                    <div className="position-relative">
                      <Tabs defaultActiveKey="animal-animation">
                        <Tab
                          eventKey="animal-animation"
                          title="Animal Animation"
                        >
                          <h3>Animal Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab
                          eventKey="character-animation"
                          title="Character Animation"
                        >
                          <h3>Character Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="game-animation" title="Game Animation">
                          <h3>Game Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="fps-animation" title="FPS Animation">
                          <h3>FPS Animation</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                        <Tab eventKey="rig" title="Rig">
                          <h3>Rig</h3>
                          <p>
                            Zombie shooter - survival games is one of the best
                            free zombie shooting games ever. survive in a
                            post-apocalypse wasteland killing zombies and
                            stopping the dead rising in the last day of the
                            world. shoot into the dead, stop the unkilled
                            invasion and save humanity in the king of fps zombie
                            survival games! take your sniper rifle and fire
                            weapons, aim to every walking dead and kill zombies
                            as a lone survivor to prevent the end of the world.
                          </p>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      <ContectCompo />
      <Footer />
      <BackToTopButton />
    </>
  );
};

export default Portfolio;
