import React from 'react';
//import { Link } from 'react-router-dom'; // Assuming you use React Router for navigation

const SitemapGenerator = () => {
  // Define your routes and their corresponding URLs
  const routes = [
    { path: '/', name: 'Home' },
    { path: '/services', name: 'Services' },
    { path: '/portfolio', name: 'Portfolio' },
    { path: '/journey', name: 'Journey' },
    { path: '/contact', name: 'Contact Us' },
    { path: '/contact-us', name: 'Contact Us' },
    // Add more routes as needed
  ];

  // Function to generate XML for each route
  const generateUrl = (route) => {
    return `
      <url>
        <loc>https://vasundharagames.com${route.path}</loc>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
      </url>
    `;
  };

  // Function to generate the complete sitemap
  const generateSitemap = () => {
    const urls = routes.map(route => generateUrl(route)).join('');
    return `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${urls}
      </urlset>
    `;
  };

  // Render the generated sitemap
  return (
    <pre>
      {generateSitemap()}
    </pre>
  );
};

export default SitemapGenerator;