import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import CustomCursor from "../components/CustomCursor";
import BackToTopButton from "../components/BackToTopButton";
import axios from "axios";

const ContectCompo = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    interest: "",
    budget: "",
  });

  const [formStatus, setFormStatus] = useState(""); // To handle success or error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email && !formData.phoneNumber) {
      setFormStatus("Please provide at least an email or phone number.");
      return; // Stop the form submission
    }

    try {
      await axios.post("https://vasundharagames.com/send-email", formData);
      setFormStatus(
        "Your message has been sent successfully! We will get back to you shortly."
      );
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        interest: "",
        budget: "",
      });
    } catch (error) {
      setFormStatus(
        "Oops! Something went wrong while sending your message. Please try again later."
      );
      console.error(error);
    }
  };
  const statusStyle = formStatus.includes("successfully")
    ? {
        color: "green",
        fontSize: "16px",
        marginTop: "10px",
        fontWeight: "bold",
      }
    : { color: "red", fontSize: "16px", marginTop: "10px", fontWeight: "bold" };

  return (
    <>
      <CustomCursor />
      <section className="contact-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 px-0">
              {/* <img
                src="/images/png/vasundhara-building.webp"
                className="img-fluid w-100"
                alt="vasundhara-building"
              /> */}
            </div>
            <div className="col-lg-5 px-0 align-items-center">
              <div className="contact-form ">
                <h2>Contact Us</h2>
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                  <Form.Select
                    name="interest"
                    value={formData.interest}
                    onChange={handleChange}
                  >
                    <option value="">I am interested in</option>
                    <option value="Web Development">Game Development</option>
                    <option value="App Development">2D/3D Animation</option>
                    <option value="Game Development">2D/3D Art</option>
                    <option value="Game Development">
                      Website Development
                    </option>
                    <option value="Game Development">App Development</option>
                    <option value="Game Development">Other</option>
                  </Form.Select>
                  <Form.Select
                    name="budget"
                    value={formData.budget}
                    onChange={handleChange}
                  >
                    <option value="">What do you need from us?</option>
                    <option value="Advise">Advise</option>
                    <option value="Develop from Scratch">
                      Develop from Scratch
                    </option>
                    <option value="Support & Maintenance">
                      Support & Maintenance
                    </option>
                    <option value="Optimize">Optimize</option>
                  </Form.Select>
                  <p>
                    This form collects your details to add you to our monthly
                    newsletter list. We treat your data with the utmost security
                    and will never sell it to third parties. Read our{" "}
                    <Link to="/">privacy policy</Link> for more.
                  </p>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Contact Us
                      <FontAwesomeIcon icon={faArrowUp} className="ms-2" />
                    </button>
                  </div>
                  {formStatus && <p style={statusStyle}>{formStatus}</p>}{" "}
                  {/* Display success or error message */}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BackToTopButton />
    </>
  );
};

export default ContectCompo;
